import React from 'react';
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import Content from "@components/Content";
import ReactMarkdown from "react-markdown";

import './page.scss'

const PageTemplate = ({ data }) => (
	<Layout>
		<Content>
			<h1 className="heading">{data.strapiPage.title}</h1>
			<ReactMarkdown source={data.strapiPage.content} />
		</Content>
	</Layout>
)

export default PageTemplate

export const query = graphql`
	query PageTemplate($id: String!) {
		strapiPage(id: {eq: $id}) {
			title
			content
		}
	}`
